<template>
  <div>
    <div class="wrapper d-flex align-items-stretch">
      <MainMenu />
      <div id="content" class="p-4 p-md-5 pt-5">
        <h1 class="text-center">{{ $t("Copla.VttToSrt.Title") }}</h1>
        <form @submit="checkForm" id="formulario">
          <div class="border rounded secciones">
            <div class="form-group row">
              <label for="file" class="col-2 col-form-label text-center">{{
                $t("Copla.VttToSrt.File")
              }}</label>
              <div class="col-10">
                <input
                  type="file"
                  class="form-control"
                  id="file"
                  name="file"
                  ref="file"
                  v-on:change="handleFileUpload()"
                  accept=".vtt"
                  required
                />
              </div>
            </div>
          </div>
          <div
            class="border a rounded secciones mb-2 mt-3"
            style="text-align: left"
          >
            <h2 style="display: inline; margin-right: 20px">
              {{ $t("Copla.MercurioSubtitles.Format.Title") }}
            </h2>
            <div class="form-group row mt-4">
              <label for="lines" class="col-2 col-form-label text-center">{{
                $t("Copla.MercurioSubtitles.Format.Lines")
              }}</label>
              <div class="col-10">
                <input
                  type="number"
                  class="form-control"
                  name="lines"
                  id="lines"
                  min="1"
                  v-model="lineas"
                />
              </div>
            </div>
            <div class="form-group row">
              <label
                for="characters"
                class="col-2 col-form-label text-center"
                >{{ $t("Copla.MercurioSubtitles.Format.Characters") }}</label
              >
              <div class="col-10">
                <input
                  type="number"
                  class="form-control"
                  name="characters"
                  id="characters"
                  min="1"
                  max="120"
                  v-model="caracteres"
                />
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col">
              <div class="text-center">
                <button
                  type="submit"
                  id="comenzar"
                  name="comenzar"
                  class="btn btn-success"
                >
                  {{ $t("Copla.MercurioSubtitles.Button") }}
                </button>
              </div>
            </div>
          </div>
        </form>
        <div class="border rounded secciones mt-4" id="messages">
          <span v-html="mensajes"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import MainMenu from "../components/MainMenu";
export default {
  name: "SRT2-1",
  components: {
    MainMenu
  },
  data() {
    return {
      file: "",
      base_url: process.env.VUE_APP_BASE_URL,
      mensajes: "",
      lineas: "2",
      caracteres: "35"
    };
  },
  methods: {
    handleFileUpload: function() {
      this.file = this.$refs.file.files[0];
    },
    checkForm: function(e) {
      e.preventDefault();
      let self = this;
      let formData = new FormData();
      formData.append("file", this.file);
      formData.append("_lines", this.lineas);
      formData.append("_characters", this.caracteres);
      this.mensajes = "Transformando el archivo...";
      let serverURL = "https://formatsubtitles." + this.base_url + "/";
      axios
        .post(serverURL + "api/v1/VTTtoSRT", formData, {
          headers: { "Content-Type": "multipart/form-data" }
        })
        .then(function(response) {
          if (response.status == 200) {
            self.mensajes =
              "Download file: <a href='" +
              serverURL +
              response.data.data +
              "'>SRT file</a>";
          } else {
            self.mensajes = response.status;
          }
        })
        .catch(function(response) {
          self.mensajes = response;
        });
    }
  },
  computed: {
    srt: function() {
      if (this.tipo == "srt") return true;
      else return false;
    }
  }
};
</script>

<style scoped>
h1 {
  font-size: 1.8em;
  font-weight: bold;
}
h2 {
  text-align: left;
  font-size: 1.3em;
  font-weight: bold;
}
.secciones {
  padding: 10px;
}
#messages {
  min-height: 100px;
}
.wrapper {
  width: 100%;
}

h1 {
  font-size: 1.8em;
  font-weight: bold;
}
.align-items-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

#content {
  width: 100%;
  padding: 0;
  min-height: 100vh;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  background-color: white;
}
</style>
